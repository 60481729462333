<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in modelItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.id"
      :class="{ 'mb-05': slotConfig.show_add_btn }"
    >

      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1">
            <b-form-file
              :ref="`${slotConfig.name}_${itemIndex}_file`"
              :disabled="(slotConfig.add_disable === true && !isEdit) || (slotConfig.edit_disable === true && isEdit)"
              v-model="item.value"
              @change="onFileChange(itemIndex, arguments[0])"
              @input="onInput(itemIndex, arguments[0])"
              :class="slotConfig.input_class"
              :accept="slotConfig.file_accept"
              :placeholder="slotConfig.placeholder"
              :drop-placeholder="$t('batchupload.drop_placeholder')"
              :browse-text="$t('batchupload.browse')"
            />
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="secondary"
            @click="deleteItem(itemIndex)"
            :disabled="modelItems.length <= slotConfig.min_count"
          >
            {{ $t('common.delete') }}
          </b-button>
        </b-col>

        <validation-provider
          tag="div"
          #default="{ errors }"
          :ref="`${slotConfig.name}_${itemIndex}_main`"
          :vid="`${slotConfig.name}_${itemIndex}_main`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="slotConfig.rule"
        >
          <b-col
            cols="12"
            v-show="errors.length !== 0 && (!slotConfig.check_duplicate || !duplicateItems.includes(item.value ? item.value.name : ''))"
          >
            <input type="hidden" :value="item.value ? item.value.name : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>
      </b-row>

      <validation-provider
        tag="b-row"
        :ref="`${slotConfig.name}_${itemIndex}_sub`"
        #default="{ errors }"
        :vid="`${slotConfig.name}_${itemIndex}_sub`"
        :name="$t(slotConfig.label)"
        :customMessages="{ length: slotConfig.duplicate_tips}"
        rules="length:0"
        v-if="slotConfig.check_duplicate"
        v-show="duplicateItems.includes(item.value.name)"
      >
        <input type="hidden" :value="duplicateItems.includes(item.value.name) ? '_' : ''"/>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="primary" @click="addItem()"
      :disabled="modelItems.length >= slotConfig.max_count"
    >
      {{ $t('common.add') }}
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@validations'
import { getDuplicateItems } from '@/libs/ez-utils'

export default {
  name: 'FieldFiles',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    input_class: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    rootItem: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      autoIndex: 0,
      modelItems: [],
      emptyValue: null,
    }
  },

  computed: {
    duplicateItems() {
      return this.slotConfig.check_duplicate
        ? getDuplicateItems(this.modelItems.map(item => item.value.name)
          .filter(value => value.name !== ''))
        : []
    },
    slotConfig() {
      return Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: false,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config)
    }
  },

  watch: {
    items: {
      handler(values, _oldValues) {
        for (const [i, value] of values.entries()) {
          while (this.modelItems.length >= i + 1 && value !== this.modelItems[i].value) {
            this.deleteItem(i, false)
          }
          if (this.modelItems.length < i + 1) {
            this.addItem(value)
          }
        }
      },
      deep: true,
    },
    slotConfig: {
      handler(_value, _oldValue) {
        const that = this
        this.modelItems.forEach((item, i) => that.onInput(i))
      },
      deep: true,
    }
  },

  created() {
    this.modelItems = this.items.map((value, index) => ({
      value,
      id: this.autoIndex++
    }))
    const remainCounts = this.slotConfig.min_count - this.modelItems.length
    for (let i = 0; i < remainCounts; i += 1) {
      this.addItem()
    }
  },

  methods: {
    addItem: function (value, isUpdate = true) {
      this.modelItems.push({
        value: value === undefined ? this.emptyValue : value,
        id: this.autoIndex++
      })
      isUpdate && this.updateValues()
    },
    deleteItem: function (itemIndex, isUpdate = true) {
      this.modelItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },
    onInput(itemIndex, file = null, isUpdate = true) {
      file = file ?? this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].file
      if (!file) return

      // 文件過大時提示
      const currentFileSizeMB = _.round(file.size / 1024 / 1024, 2)
      const maxFileSizeMB = _.round((this.slotConfig.file_max_size ?? 0) / 1024 / 1024, 2)
      if (maxFileSizeMB && currentFileSizeMB > maxFileSizeMB) {
        return common.showAlert({
          title: common.getI18n('common.form_file_max_size_tip')
            .replace('{_maxFileSize_}', maxFileSizeMB + 'MB')
            .replace('{_currentFileSize_}', currentFileSizeMB + 'MB'),
          fun: () => this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].reset(this.emptyValue),
        })
      }

      const fileSuffix = file?.name?.slice(file.name.lastIndexOf('.')) ?? ''
      const fileTypesAccept = this.slotConfig?.file_accept?.split(',') ?? []
      if (!file?.name || fileTypesAccept.indexOf(fileSuffix) >= 0) {
      } else {
        file = this.emptyValue
        console.log(this.$refs[`${this.slotConfig.name}_${itemIndex}_file`])
        this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].reset(this.emptyValue)
      }
      this.modelItems[itemIndex].value = file
      isUpdate && this.updateValues()
    },
    onBlur(itemIndex, value) {
      if (this.slotConfig.check_duplicate) {
        this.modelItems.forEach((v, i) => {
          this.$refs[`${this.slotConfig.name}_${i}_sub`][0].validate()
        })
      }
    },
    onFileChange() {
    },
    updateValues() {
      this.$emit('updateValues', this.modelItems.map(item => item.value))
    },
    clearFiles() {
      this.modelItems.forEach((item, itemIndex) => {
        this.$refs[`${this.slotConfig.name}_${itemIndex}_file`][0].reset(this.emptyValue)
      })
    },
    buildData() {
    }
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>

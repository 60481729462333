import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils';

let form_options = {}
form_options.environments = []
form_options.platforms = []

export function reloadFieldData() {
  // init Arrays
  ;[
    form_options.environments,
    form_options.platforms,
  ].forEach(arr => arr.length = 0)

  kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions }) => {
    envOptions.forEach(option => form_options.environments.push(option) )
    platformOptions.forEach(option => form_options.platforms.push(option) )
  })
}



export default [
  {
    name: 'platform',
    label: 'kiosk.device_platform',
    type: 'select',
    rule: 'required',
    options: form_options.platforms,
    edit_disable: true,
  },
  {
    name: 'packageName',
    label: 'kiosk.package_name',
    type: 'text',
    rule: 'required',
    add_show: false,
    edit_show: true,
    edit_disable: true,
  },
  {
    name: 'packageFiles',
    label: 'kiosk.package_file',
    rule: 'required',
    add_show: true,
    edit_show: false,
    type: 'slot',
    validate_in_slot: true,
    slot: 'files',
    slot_config: {
      name: 'packageFiles',
      label: 'kiosk.package_file',
      type: 'file',
      file_accept: '.zip, .apk',
      file_max_size: 200 * 1024 * 1024, // 200MB
      placeholder: common.getI18n('kiosk.placeholder').replace('{_type_}', '.zip/.apk'),
      rule: 'required',
      max_count: 1,
      show_del_btn: false,
      show_add_btn: false,
      add_disable: true,
    },
  },
  {
    name: 'version',
    label: 'kiosk.package_version',
    type: 'text',
    rule: { required: true, regex: /^\s*\d+(?:\.\d+){1,3}\s*$/ },
    custom_messages: {
      regex: '請輸入正確的版本號（如 1.0.0.1）',
    },
    trim: true,
  },
  {
    name: 'isMandatory',
    label: 'kiosk.force_upgrade',
    type: 'select',
    rule: 'required',
    options: [
      {
        text: '是',
        value: true,
      },
      {
        text: '否',
        value: false,
      },
    ],
  },
  {
    name: 'remark',
    label: 'kiosk.package_remark',
    type: 'text',
    rule: { required: true, regex: /^\s*.{1,100}\s*$/ },
    custom_messages: {
      regex: common.getI18n('kiosk.vld_err_packageVersion'),
    },
    trim: true,
  },
  {
    name: 'uploadedTime',
    label: 'kiosk.upload_time',
    type: 'text',
    add_show: false,
    edit_show: true,
    edit_disable: true,
  },
  {
    name: 'url',
    label: 'URL',
    type: 'text',
    add_show: false,
    edit_show: true,
    edit_disable: true,
  },
]

<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field2
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskupdate/EditPackage"
        finish-router="kioskManagePackage"
        permission="Kiosk_Menu_Installer"
      >
        <template v-slot:files="scope">
          <slot-file
            ref="slot_files"
            :root-item="scope.rootItem"
            :items="row_data['packageFiles']"
            @updateValues="(value) => row_data['packageFiles'] = value"
          />
        </template>
      </edit-field2>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField2 from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFiles from '@/components/FieldFiles.vue'
import _ from 'lodash'

export default {
  name: 'kioskManagePackageEdit',
  components: {
    EditFieldHeader,
    EditField2,
    fields,
    SlotFile: SlotFiles,
  },

  data() {
    return {
      title: common.getMenuName('kioskManagePackage') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {},
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/kioskupdate/GetPackageInfo?packageID=' + this.id)
      .then(res => {
        this.row_data = {
          ...res.data.package,
          uploadedTime: res.data.package.uploadedTime
            ? this.$moment(res.data.package.uploadedTime).format('YYYY-MM-DD HH:mm:ss')
            : '',
          url: res.data.url,
        }
      })
  },

  methods: {
    // beforeSubmit(callback) {
    //   const rowData = _.clone(this.row_data)
    //   delete rowData.packageFiles
    //   callback(true, rowData)
    // },
  },
}
</script>

<style scoped>

</style>
